import React, { FC, ReactNode } from "react";

import RootLink from "next/link";
import Label from "components/Label";
import styles from "./styles.module.scss";

type Props = {
  to: string;
  className?: string;
  variant?: string;
  onClick?(): void;
  blank?: boolean;
  color?: string;
  label?: string;
  Icon?: ReactNode;
  labelClassName?: string;
};

const Link: FC<Props> = ({
  label,
  Icon,
  to,
  className,
  variant,
  color,
  onClick,
  blank,
  labelClassName
}) => (
  <RootLink href={to}>
    <a
      rel="noreferrer"
      target={blank ? "_blank" : undefined}
      onClick={onClick}
      href={to}
      className={`${styles.base} ${className}`}
    >
      <Label
        className={labelClassName}
        variant={`button-${variant}`}
        color={color || "supplemental"}
      >
        {label}
      </Label>
      {Icon}
    </a>
  </RootLink>
);

Link.defaultProps = {
  className: undefined,
  variant: "",
  onClick: () => {},
  blank: false,
  color: undefined,
  label: "",
  Icon: null,
  labelClassName: undefined
};

export default Link;

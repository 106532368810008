import React, { FC, ReactNode } from "react";

import styled from "styled-components";
import { Theme } from "theme/types";
import { useTheme } from "theme/ThemeCreator";
import AnimateIn from "components/AnimateIn";

import styles from "./styles.module.scss";

type StyledProps = {
  theme: Theme;
};

const StyledContent = styled.div<StyledProps>`
  ${({ theme }) => {
    const { tablet, mobile, desktop } = theme.media;
    return `
      @media ${desktop} {
        width: min(1200px, 100vw);
      }

      @media ${tablet} {
        width: 100vw;
      }

      @media ${mobile} {
        width: 100vw;
      }
    `;
  }}
`;

const Content: FC<{
  id?: string;
  contentClassName?: string;
  containerClassName?: string;
  children: ReactNode;
  disableMotion?: boolean;
}> = ({
  children,
  contentClassName,
  containerClassName,
  id,
  disableMotion
}) => {
  const theme = useTheme();

  return (
    <div id={id} className={`${styles.container} ${containerClassName}`}>
      <AnimateIn disableMotion={disableMotion}>
        <StyledContent
          theme={theme}
          className={`${styles.content} ${contentClassName}`}
        >
          {children}
        </StyledContent>
      </AnimateIn>
    </div>
  );
};

Content.defaultProps = {
  contentClassName: undefined,
  containerClassName: undefined,
  id: undefined,
  disableMotion: false
};

export default Content;

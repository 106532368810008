import React, { FC, useState } from "react";
import { FiMenu as OpenNavigation } from "react-icons/fi";

import Button from "components/Button";
import Panel from "components/Panel";
import Link from "components/Link";
import { useRouter } from "next/router";
import MandalaLogo from "../../../../../../public/mandala-logo.svg";
import styles from "./styles.module.scss";

import { Props } from "../../types";
import { navigation } from "../../constants";
import Header from "../Header";

const ResponsiveTopbar: FC<Props> = ({ withHeader }) => {
  const [visible, setVisible] = useState(false);

  const router = useRouter();

  return (
    <>
      {withHeader && <Header />}
      <Panel
        size={300}
        visible={visible}
        onClose={() => setVisible(false)}
        position="right"
      >
        <div className={styles.sidePanel}>
          {navigation
            .filter(({ responsive }) => responsive)
            .map(({ label, route }) => (
              <Link
                key={route}
                onClick={() => setVisible(false)}
                to={route}
                label={label}
              />
            ))}
        </div>
      </Panel>
      <div className={styles.container}>
        <MandalaLogo
          onClick={() => {
            router.push("/");
          }}
          className={styles.logo}
        />
        <Button
          onClick={() => setVisible(true)}
          variant="text"
          Icon={<OpenNavigation className={styles.openNavigation} />}
        />
      </div>
    </>
  );
};

export default ResponsiveTopbar;

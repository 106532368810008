import React, { FC } from "react";
import { useRouter } from "next/router";
import Button from "components/Button";
import FullLogo from "../../../../../../public/full-logo-primary.svg";

import styles from "./styles.module.scss";
import Navigation from "./components/Navigation";
import { navigation } from "../../constants";
import { Props } from "../../types";
import Header from "../Header";

const DesktopTopbar: FC<Props> = ({ withHeader }) => {
  const router = useRouter();

  return (
    <div className={styles.wrapper}>
      {withHeader ? <Header /> : <div style={{ height: 50 }} />}
      <div className={styles.container}>
        <div>
          <FullLogo
            onClick={() => {
              router.push("/");
            }}
            className={styles.logo}
          />
        </div>
        <div className={styles.actionsWrapper}>
          <Navigation options={navigation} />
          <Button onClick={() => router.push("/start")} variant="accent">
            Sign up
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DesktopTopbar;

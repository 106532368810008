import React, { FC, ReactNode } from "react";
import { motion } from "framer-motion";
import { animations } from "./constants";

interface Props {
  children: ReactNode;
  animation?: "fade" | "expand-h" | "slide-l" | "slide-r";
  className?: string;
  id?: string;
  delay?: number;
  disableMotion?: boolean;
}

const AnimateIn: FC<Props> = ({
  delay,
  children,
  animation = "fade",
  className,
  id,
  disableMotion
}) => {
  const variants = animations(animation);

  return (
    <motion.div
      id={id}
      className={className}
      viewport={{ once: true }}
      initial={disableMotion ? "visible" : "hidden"}
      whileInView="visible"
      variants={variants}
      transition={{
        duration: 1,
        ease: "easeInOut",
        staggerChildren: 1,
        delay
      }}
    >
      {children}
    </motion.div>
  );
};

AnimateIn.defaultProps = {
  animation: "fade",
  id: undefined,
  className: undefined,
  delay: 0,
  disableMotion: false
};

export default AnimateIn;

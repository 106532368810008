import Link from "components/Link";
import { useRouter } from "next/router";
import React, { FC } from "react";
import styles from "./styles.module.scss";

type Props = {
  options: {
    label: string;
    route: string;
    blank?: boolean;
    desktop: boolean;
  }[];
};

const Navigation: FC<Props> = ({ options }) => {
  const { pathname } = useRouter();

  const path = `/${pathname.split("/")[1]}`;

  return (
    <div className={styles.container}>
      {options
        .filter(({ desktop }) => desktop)
        .map(({ label, route, blank }) => (
          <Link
            blank={blank}
            labelClassName={`${styles.link} ${
              route === path && styles.selected
            }`}
            key={`${label}-${route}`}
            to={route}
            label={label}
          />
        ))}
    </div>
  );
};

export default Navigation;

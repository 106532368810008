import { match, __ } from "ts-pattern";
import { mapper } from "utils/mapping";

import styles from "./styles.module.scss";

interface Option {
  className: string;
  animateOpen: { [key: string]: number | string };
  animateClose: { [key: string]: number | string };
}

const formatDimension = (size: string | number) =>
  match(size)
    .with(__.string, (value: string) => `-${value}`)
    .with(__.number, (value: number) => `-${value}px`)
    .run();

export const variants = (size: number | string): ((key: string) => Option) =>
  mapper(
    {
      right: {
        className: styles.right,
        animateOpen: { right: 0, width: size },
        animateClose: { right: formatDimension(size), width: size }
      },
      bottom: {
        className: styles.bottom,
        animateOpen: { bottom: 0, height: size },
        animateClose: { bottom: formatDimension(size), height: size }
      }
    },
    {
      className: styles.right,
      animateOpen: { right: 0, width: size },
      animateClose: { right: formatDimension(size), width: size }
    }
  );

import { mapper } from "utils/mapping";

export const animations = mapper(
  {
    fade: {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: 0 }
    },
    "expand-h": {
      visible: { width: "100%" },
      hidden: { width: 0 }
    },
    "slide-l": {
      visible: { x: 0, opacity: 1 },
      hidden: { x: "-100%", opacity: 0 }
    },
    "slide-r": {
      visible: { x: 0, opacity: 1 },
      hidden: { x: "100%", opacity: 0 }
    }
  },
  { visible: { opacity: 1 }, hidden: { opacity: 0 } }
);

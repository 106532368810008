import Label from "components/Label";
import React, { FC } from "react";

import Button from "components/Button";
import Link from "components/Link";
import { useRouter } from "next/router";
import Logo from "../../../../../../public/full-logo-primary.svg";
import styles from "./styles.module.scss";

const DesktopFooter: FC = () => {
  const router = useRouter();

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div>
          <Logo className={styles.logo} />
          <Label variant="small-capitalized" color="supplemental">
            Hello@humminghomes.com
          </Label>
        </div>
        <div className={styles.topActionsWrapper}>
          <div className={styles.topActions}>
            <Link
              variant="small"
              labelClassName={styles.topLink}
              to="/#how-it-works"
              label="How it works"
            />
            <Link
              variant="small"
              labelClassName={styles.topLink}
              to="/about"
              label="About us"
            />
            <Link
              variant="small"
              labelClassName={styles.topLink}
              to="/contact-us"
              label="Contact us"
            />
            <Link
              variant="small"
              labelClassName={styles.topLink}
              to="/services"
              label="Services"
            />
            <Link
              variant="small"
              labelClassName={styles.topLink}
              to="/vendors"
              label="Vendors"
            />
            <Link
              variant="small"
              labelClassName={styles.topLink}
              to="/about/#careers"
              label="Careers"
            />
            <Link
              variant="small"
              labelClassName={styles.topLink}
              to="/share"
              label="Refer a friend"
            />
          </div>
          <Button
            className={styles.signIn}
            size="medium"
            variant="accent"
            onClick={() => {
              router.push("/start");
            }}
          >
            Sign up
          </Button>
        </div>
      </div>
      <div className={styles.bottom}>
        <Label variant="capitalized-small" color="supplemental">
          © Copyright 2021 Humming Homes, INC
        </Label>
        <div className={styles.bottomActions}>
          <Link variant="small" to="/terms" label="Terms of service" />
          <Link variant="small" to="/privacy" label="Privacy policy" />
          <Link
            variant="small"
            to="/our-privacy-policy"
            label="Your CA privacy rights"
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopFooter;

import React, { FC } from "react";

import Media from "components/Media";
import ResponsiveTopbar from "./components/ResponsiveTopbar";
import DesktopTopbar from "./components/DesktopTopbar";
import { Props } from "./types";

const Topbar: FC<Props> = ({ withHeader }) => {
  return (
    <>
      <Media at={["responsive"]}>
        <ResponsiveTopbar withHeader={withHeader} />
      </Media>
      <DesktopTopbar withHeader={withHeader} />
    </>
  );
};

export default Topbar;

import React, { FC } from "react";
import Label from "components/Label";

import Button from "components/Button";
import Media from "components/Media";
import { useRouter } from "next/router";
import styles from "./styles.module.scss";

const Header: FC = () => {
  const router = useRouter();

  return (
    <>
      <Media at={["mobile", "tablet"]}>
        <div className={styles.responsiveContainer}>
          <Label
            linkColor="white"
            useMarkdown
            variant="body-centered"
            color="primary2"
          >
            {`[Sign up](/start). We’re live in Westchester County (NY), South
            Florida, Fairfield County (CT) and the Hamptons!`}
          </Label>
        </div>
      </Media>
      <Media at={["desktop"]}>
        <div className={styles.container}>
          <Label className={styles.prompt} variant="h6" color="primary">
            We’re live in Westchester County (NY), South Florida, Fairfield
            County (CT) and the Hamptons!
          </Label>
          <Button
            labelProps={{ breakWord: false }}
            variant="primary"
            onClick={() => {
              router.push("/start");
            }}
          >
            Sign up
          </Button>
        </div>
      </Media>
    </>
  );
};

export default Header;

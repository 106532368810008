export const navigation = [
  { label: "Home", route: "/", desktop: true, responsive: true },
  {
    label: "About us",
    route: "/about",
    desktop: true,
    responsive: true
  },
  { label: "Services", route: "/services", desktop: true, responsive: true },
  {
    label: "For Vendors",
    route: "/vendors",
    desktop: true,
    responsive: true
  },
  {
    label: "FAQ",
    route: "/faq",
    desktop: true,
    responsive: true
  },
  {
    label: "Pricing",
    route: "/start",
    desktop: true,
    responsive: true
  }
];

import React, { FC } from "react";

import Media from "components/Media";
import DesktopFooter from "./components/DesktopFooter";
import MobileFooter from "./components/MobileFooter";

const Footer: FC = () => {
  return (
    <>
      <Media at={["desktop"]}>
        <DesktopFooter />
      </Media>
      <Media at={["responsive"]}>
        <MobileFooter />
      </Media>
    </>
  );
};

export default Footer;
